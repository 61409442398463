<template>
  <div class="page-rules">
    <div class="page-rules__wrap">
      <p class="text-center bold">ОФІЦІЙНІ ПРАВИЛА ПРОВЕДЕННЯ АКЦІЇ</p>
      <p class="text-center bold">«Смачний Великдень з «Хуторок»</p>
      <p class="text-center bold">(далі за текстом – «Правила»)</p>
      <ol>
        <li>1. <strong>Загальні умови Акції:</strong></li>
        <li>
          1.1 <strong>Виконавцем є</strong> - ТОВАРИСТВО З ОБМЕЖЕНОЮ
          ВІДПОВІДАЛЬНІСТЮ «ВИДАВНИЦТВО УКРАЇНСЬКИЙ МЕДІА ДІМ»,
          місцезнаходження: 03110, м. Київ, вул. Миколи Амосова, буд.4, оф.10,
          ідентифікаційний код юридичної особи 40459518.
        </li>
        <li>
          1.2 <strong>Організатором є</strong> ТОВАРИСТВО З ОБМЕЖЕНОЮ
          ВІДПОВІДАЛЬНІСТЮ «ЗОВНІШТОРГРЕСУРС», місцезнаходження: 49000, Україна,
          Дніпропетровська обл., м. Дніпро, проспект Олександра Поля, буд. 103,
          кабінет 207, ідентифікаційний код юридичної особи 39110589.
        </li>
        <li>
          1.3 <strong>Замовником є</strong> ТОВАРИСТВО З ОБМЕЖЕНОЮ
          ВІДПОВІДАЛЬНІСТЮ «ЗОВНІШТОРГРЕСУРС», місцезнаходження: 49000, Україна,
          Дніпропетровська обл., м. Дніпро, проспект Олександра Поля, буд. 103,
          кабінет 207, ідентифікаційний код юридичної особи 39110589.
        </li>
        <li>
          1.4 <strong>Сайт Акції</strong> – – веб-сайт, за адресою в мережі
          Інтернет
          <a href="https://pascha.smak.ua/" target="_blank"
            >https://pascha.smak.ua/</a
          >, яким керує Виконавець.
        </li>
        <li>
          1.5 Акція не є лотереєю або послугою у сфері грального бізнесу. Фонд
          Заохочень Акції не формується з внесків Учасників Акції.
        </li>
        <li>
          <strong>Територія проведення Акції</strong> - Акція проводиться на
          території України (за винятком тимчасово окупованої території в межах
          визначених відповідно законодавства України, виключно з міркувань
          безпеки мешканців даних регіонів) через глобальну мережу Інтернет на
          Сайті Акції, з урахуванням особливостей мережі Інтернет;
        </li>
        <li>
          Дане тимчасове вимушене обмеження діє виключно з міркувань безпеки
          мешканців цих регіонів та неможливості з боку Виконавця/Організатора
          гарантувати належне проведення Акції, зокрема, але не обмежуючись, в
          частині відправлення/доставки/вручення Заохочень Учасникам за
          адресами, що знаходяться на територіях Автономної Республіки Крим та
          м. Севастополь, а також деяких населених пунктах Донецької та
          Луганської областей.
          <strong
            >Загальний Строк проведення Акції (надалі – строк/період проведення
            Акції</strong
          >
          – Акція проводиться у період з «13» годин «00» хвилин за Київським
          часом 14 квітня 2021 року до «23» годин «00» хвилин за Київським часом
          28 квітня 2021 року включно:
        </li>
        <li>
          <strong>Результати учасників приймаються</strong> в період з «13»
          годин «00» хвилин за Київським часом 14 квітня 2021 року до «23» годин
          «00» хвилин за Київським часом 27 квітня 2021 року.
        </li>
        <li>
          <p><strong>Визначення Переможців</strong> буде здійснено у період:</p>
          <ul>
            <li class="padding-left__20">
              з «11» годин «00» хвилин до «13» годин «00» хвилин за Київським
              часом 21 квітня 2021 року;
            </li>
            <li class="padding-left__20">
              перший розіграш у період з «11» годин «00» хвилин до «13» годин
              «00» хвилин за Київським часом 28 квітня 2021 року;
            </li>
            <li class="padding-left__20">
              другий розіграш у період з «14» годин «00» хвилин до «17» годин
              «00» хвилин за Київським часом 28 квітня 2021 року.
            </li>
          </ul>
        </li>
        <li>
          <p>
            Про визначення переможців у періоди детально зазначено у п. 6.2, 6.3
            цих Правил.
          </p>
          <ul>
            <li class="padding-left__20">
              Учасник Акції - особа, яка у період проведення Акції виконала усі
              її умови (надалі – Учасник).
            </li>
            <li class="padding-left__20">
              Переможець – Учасник Акції, який виконав умови Акції та отримав
              право на отримання Заохочення.
            </li>
          </ul>
        </li>
      </ol>
      <ol>
        <li>
          2. <strong>Порядок і спосіб інформування про правила Акції</strong>
        </li>
        <li>
          2.1. Інформування щодо Правил Акції здійснюється за допомогою
          розміщення Правил рекламної Акції на Сайті Акції протягом всього
          Строку проведення Акції.
        </li>
        <li>
          2.2 Правила Акції може бути змінено та/або доповнено
          Виконавцем/Організатором Акції в порядку, передбаченому чинним
          законодавством України. Зміна та/або доповнення Правил рекламної Акції
          можливі у випадку їхнього затвердження Виконавцем/Організатором Акції
          та оприлюднення в порядку, що визначений для інформування про Правила
          Акції. Такі зміни та доповнення набувають чинності з моменту
          опублікування, та мають відношення до всіх Учасників Акції, не залежно
          від дати реєстрації в Акції, якщо інше не буде спеціально визначено
          безпосередньо змінами/доповненнями до Правил.
        </li>
      </ol>
      <ol>
        <li>3. <strong>Вимоги до Учасників Акції:</strong></li>
        <li>
          3.1. В Акції можуть приймати участь громадяни України, що на момент
          початку Акції досягли 18 років, які постійно проживають на території
          України (за винятком тимчасово окупованих територій у Донецькій та
          Луганській областях, Автономної Республіки Крим та міста Севастополя,
          в межах, що визначені чинним законодавством України), та які в Період
          проведення Акції виконали умови участі, визначені в розділі 5 цих
          Правил (далі — «Учасники Акції» або «Учасники»). Приймаючи участь в
          Акції, кожен Учасник тим самим підтверджує факт ознайомлення з цими
          Правилами, підтверджує свою згоду з ними та зобов’язується їх
          дотримуватися.
        </li>
        <li>
          3.2. Учасником вважається особа, яка відповідає вимогам розділу 3
          даних Правил та належним чином виконала усі умови даних Правил.
        </li>
        <li>
          3.3. Учасниками Акції не визнаються та не можуть бути наступні особи,
          незалежно від виконання ними умов даних Правил:
        </li>
        <li>
          3.3.1. Працівники Виконавця/Організатора Акції і будь-яких інших осіб,
          які беруть участь у підготовці та проведенні Акції, та їх близькі
          родичі (чоловік/дружина, діти, брати/сестри, батьки);
        </li>
        <li>3.3.2. особи віком до 18 років;</li>
        <li>
          3.3.3. особи, що не є громадянами України та особи, що не проживають
          на території України на постійній основі.
        </li>
        <li>
          3.4. Участь у рекламній Акції обмежено дієздатних та недієздатних осіб
          здійснюється відповідно до чинного законодавства України.
          Виконавець/Організатор Акції не зобов’язаний перевіряти правоздатність
          та/або дієздатність Учасників Акції.
        </li>
        <li>3.5. Учасники під час участі в Акції зобов’язуються:</li>
        <li>
          3.5.1. дотримуватися вимог Правил та норм чинного законодавства
          України;
        </li>
        <li>3.5.2. дотримуватися правил користування Сайтом;</li>
        <li>
          3.5.3. вказувати повні, коректні та достовірні відомості, визначені у
          даних Правилах;
        </li>
        <li>
          3.5.4. свідомо не завдавати незручностей та не чинити перешкод іншим
          Учасникам;
        </li>
        <li>
          3.5.5. не чинити дій, що ставлять під сумнів правомірність участі
          такого Учасника в Акції.
        </li>
        <li>
          3.6. Беручи участь в Акції, Учасник розуміє та погоджується з тим, що
          будь-яка добровільно надана ним інформація, у тому числі персональні
          дані Учасника, може оброблятися Виконавцем/Організатором, або
          залученими ними третіми особами з метою подальшого можливого - умови
          даних Правил, Учасники тим самим надають згоду на таку обробку
          персональних даних з метою використання Виконавцем/Організатором
          та/або іншими уповноваженими Виконавцем/Організатором Акції особами.
        </li>
        <li>
          3.7. Беручи участь в Акції, кожен Учасник тим самим підтверджує свою
          згоду на безкоштовне використання наданої ним інформації про себе
          Виконавцем/Організатором Акції, або залученими ними третіми особами, з
          маркетинговою та/чи будь-якою іншою метою методами, що не порушують
          чинне законодавство України (в т.ч. шляхом передачі третім особам) та
          обсягу наданої згоди, зокрема, на безоплатне використання його імені,
          прізвища, зображення Учасника, інтерв’ю або інших матеріалів про нього
          з рекламною/маркетинговою метою, в т.ч., але не обмежуючись, право
          публікації (в т.ч. його імені і зображення) в засобах масової
          інформації, будь-яких друкованих, аудіо- та відеоматеріалах, інтерв’ю
          зі ЗМІ, а також для надсилання інформації, повідомлень (в т.ч.
          рекламного характеру) тощо, без будь-яких обмежень за територією,
          часом та способом використання, і таке використання жодним чином не
          відшкодовуватиметься Виконавцем/Організатором Акції та/або будь-якою
          третьою особою. Надання такої згоди розглядається у розумінні ст. 296,
          307, 308 Цивільного Кодексу України та Закону України «Про захист
          персональних даних».
        </li>
        <li>
          3.8. Надання Учасником даної Акції
          невірних/неіснуючих/недостовірних/чужих даних, у т.ч. вигаданих,
          звільняє Виконавця/Організатора від обов’язку вручити/надіслати
          Заохочення передбачені умовами Акції, і такий Учасник, який здобув
          право на отримання Заохочення Акції, але надав
          невірні/неіснуючі/недостовірні/чужі дані, втрачає право на отримання
          такого Заохочення.
        </li>
        <li>
          3.9. Виконавець/Організатор Акції гарантує, що під час збору і
          подальшого використання отриманої інформації, він буде дотримуватися
          всіх положень чинного законодавства України щодо недоторканності
          особистої інформації, а також застосовувати всі доцільні запобіжні
          заходи стосовно зібраної інформації і надавати її лише тим, хто має на
          це достатні законні підстави та у порядку, що визначений чинним
          законодавством України.
        </li>
        <li>3.10. Загальна кількість Учасників Акції є необмеженою.</li>
        <li>
          3.11. Виконавець/Організатор Акції на свій власний розсуд можуть
          визнати недійсними будь – яку реєстрацію, а також заборонити подальшу
          участь в даній Акції будь-якій особі, яка підроблює або отримує вигоду
          з підробки процесу реєстрації або проведення Акції, здійснює множинну
          реєстрацію, або ж діє, порушуючи дані Правила, діє деструктивним чином
          або здійснює дії з наміром докучати, ображати, загрожувати або
          заподіювати шкоду будь-якій іншій особі, яка може бути пов'язана із
          даною Акцією.
        </li>
      </ol>
      <ol>
        <li>4. <strong>Фонд Заохочень Акції</strong></li>
        <li>
          Фонд Заохочень Акції включає такі заохочення (далі – «Заохочення»):
        </li>
        <li>
          4.1. Фонд Заохочень Акції складається з 21 (двадцяти одного)
          Заохочення Акції, по одному Заохоченню для кожного Переможця, а саме:
        </li>
        <li>
          4.1.1. 20 (двадцять) Заохочень Першого рівня, а саме: продуктовий
          кошик з 7 (семи) одиниць продукції під ТМ «Хуторок», кошик до переліку
          не входить. За організацію, відправлення та вручення даного заохочення
          відповідає Замовник;
        </li>
        <li>
          4.1.2. 1 (одне) Заохочення Другого рівня, а саме: хлібопіч. За
          організацію даного заохочення відповідає Виконавець.
        </li>
        <li>
          4.2. Заміна Заохочень грошовим еквівалентом або будь-яким іншим благом
          не допускається. Заохочення обміну та поверненню не підлягають.
        </li>
        <li>
          4.3. За якість та характеристики Заохочень Акції відповідальність
          несуть відповідні виробники таких товарів/продукції.
        </li>
        <li>
          4.4. Загальний фонд Заохочень Акції є обмеженим та складає визначену
          цими Правилами кількість.
        </li>
        <li>
          4.5. Відповідальність Виконавця/Організатора обмежується вартістю та
          кількістю Заохочень, передбачених цими Правилами.
        </li>
        <li>4.6. Заохочення Акції обміну та поверненню не підлягають.</li>
        <li>
          4.7. Заохочення можуть бути отримані Переможцями Акції тільки за умови
          виконання цих Правил. Невиконання будь-яких умов, передбачених цими
          Правилами, позбавляє відповідного Переможця Акції права на одержання
          належного йому Заохочення. При цьому, відповідний Переможець
          вважається таким, що відмовився від отримання відповідного Заохочення
          та не має права на одержання від Виконавця/Організатора будь-якої
          компенсації, в тому числі грошової.
        </li>
        <li>
          4.8. Заохочення Акції, які залишилися не витребуваними у зв'язку з
          тим, що Учасники Акції не виконали умови цих Правил, або якщо
          Переможці Акції відмовилися від отримання таких Заохочень,
          використовуються на розсуд Виконавця/Організатора Акції.
        </li>
        <li>
          4.9. Виконавець/Організатор Акції не несуть ніякої відповідальності
          стосовно подальшого використання Заохочень Переможцями Акції після їх
          одержання, за неможливість Переможців Акції скористуватись наданими їм
          Заохоченнями з будь-яких причин, а також за можливі наслідки
          використання таких Заохочень Акції.
        </li>
      </ol>
      <ol>
        <li>5. <strong>УМОВИ УЧАСТІ В АКЦІЇ</strong></li>
        <li>
          5.1. Щоб стати претендентом на отримання Заохочення, Учаснику
          необхідно:
        </li>
        <li>5.1.1. Пройти гру на Сайті шляхом натискання на кнопку «Грати».</li>
        <li>
          Гра складається з 2 (двох) етапів. Перший етап – «Спіймай всі шматочки
          щоб зібрати паску», другий етап – «Час прикрашати паску». Учасник має
          правильно зібрати усі інгредієнти Великодньої паски протягом 2 (двох)
          хвилин. Якщо Учасник не встиг зібрати усі інгредієнти протягом
          зазначеного часу, то він не може стати претендентом на отримання
          Заохочення і на екрані його пристрою з’явиться повідомлення «Нажаль,
          спроба хибна». Після чого у Учасника виникне право повторно зіграти в
          гру на Сайті Акції шляхом натискання на кнопку «Спробувати ще раз».
        </li>
        <li>
          Якщо Учасник правильно вибрав усі інгредієнти, то на екрані його
          пристрою з’явиться повідомлення «Вітаємо! Ви вдало зібрали та
          прикрасили паску», після чого у Учасника виникне право зареєструватися
          шляхом натискання на кнопку «Зареєструватися» та стати претендентом на
          отримання Заохочення.
        </li>
        <li>
          5.1.2. Заповнити анкету реєстрації вказавши своє ім’я та номер
          телефону. Номер телефона Учасника Акції має бути активним.
        </li>
      </ol>
      <ol>
        <li>6. <strong>Визначення Переможців Акції:</strong></li>
        <li>
          6.1. Переможець Акції – це Учасник Акції, який виконав умови цих
          Правил Акції та здобув право на отримання одного з Заохочень Акції.
          Учасник Акції може бути Переможцем Акції лише один раз.
        </li>
        <li>
          <p>
            6.2. Визначення Переможців Акції здійснюється методом випадкового
            вибору серед учасників, що виконали умови Акції:
          </p>
          <ul>
            <li class="padding-left__20">
              21 квітня 2021р. не пізніше «13» годин «00» хвилин за Київським
              часом – 10 (десять) осіб;
            </li>
            <li class="padding-left__20">28 квітня 2021р.;</li>
            <li class="padding-left__20">
              перший розіграш не пізніше «13» годин «00» хвилин за Київським
              часом – 10 (десять) осіб;
            </li>
            <li class="padding-left__20">
              другий розіграш – визначення Головного Переможця Акції не пізніше
              «17» годин «00» хвилин за Київським часом – 1 (одна) особа.
            </li>
          </ul>
        </li>
        <li>
          Якщо Організатор Акції не зможе зв’язатися із Учасником Акції протягом
          7 (семи) робочих днів з моменту обрання та оприлюднення переможців на
          Сайті Акції за номером телефону, який Учасник вказав під час
          реєстрації, то такий Учасник втрачає право на отримання Заохочення. В
          такому випадку буде додатково проведено визначення Учасника протягом
          1(одного) робочого дня, замість того, що втратив право на отримання
          Заохочень, за допомогою програми вибору випадкових чисел, що
          знаходиться за посиланням
          <a href="https://www.random.org/" target="_blank"
            >https://www.random.org/</a
          >.
        </li>
        <li>
          6.3. Загалом буде визначено 20 (двадцять) Переможців Акції та 1
          (одного) Головного Переможця Акції серед усіх Учасників Акції, які
          виконали умови та правила Акції. Визначення Переможців Акції та
          Головного Переможця Акції відбувається за допомогою програми вибору
          випадкових чисел, що знаходиться за посиланням
          <a href="https://www.random.org/" target="_blank"
            >https://www.random.org/</a
          >. Зазначеною програмою буде сформовано перелік з Учасників Акції,
          Переможцями Акції визнаються Учасники Акції, вказані під номерами від
          одного до одинадцяти згенерованому сервісом Random.org.
        </li>
        <li class="text-indent__20">
          21 квітня 2021р. відбудеться обрання 10 (десяти) переможців Першого
          рівня серед Учасників, які претендентами на отримання Заохочення,
          відповідно до п.5.1 цих Правил. Переможці Акції посідають з першого по
          десяте місце в списку згенерованому сервісом Random.org за період
          реєстрації з 14 квітня 2021 р. по 20 квітня 2021 р. включно. При цьому
          переможці, визначені у зазначений період втрачають право на взяття
          участі під час визначення переможців Першого рівня 28 квітня та
          визначення Переможця Головного Призу.
        </li>
        <li class="text-indent__20">
          28 квітня 2021р. відбудеться обрання 10 (десяти) переможців Першого
          рівня серед Учасників, які претендентами на отримання Заохочення,
          відповідно до п.5.1 цих Правил. Переможці Акції посідають з першого по
          десяте місце в списку згенерованому сервісом Random.org за період
          реєстрації з 21 квітня 2021 р. по 27 квітня 2021 р. включно. При цьому
          переможці, визначені у зазначений період втрачають право на взяття
          участі під час визначення переможця Головного Призу.
        </li>
        <li class="text-indent__20">
          28 квітня 2021р. відбудеться обрання 1 (одного) переможця Другого
          рівня - Переможця Головного Призу. Переможець Головного Призу посідає
          перше місце в списку згенерованому сервісом Random.org за період
          реєстрації з 14 квітня 2021 р. по 27 квітня 2021 р. включно.
          Переможцем Другого рівня не може бути уже раніше вибраній переможець
          Першого рівня. При отриманні в списку згенерованому сервісом
          Random.org уже раніше вибраного переможця Першого рівня, обирається
          наступний в списку згенерованому сервісом Random.org учасник.
        </li>
        <li>
          6.4. Кожен з 21 Переможців Акції здобуває право отримати лише 1 (одне)
          з Заохочень, що вказані у п. 4.1. цих Правил.
        </li>
        <li>
          6.5. Результати визначення Переможців також відображаються
          Виконавцем/Організатором на Сайті протягом двох робочих днів з дня
          визначення Переможців Акції.
        </li>
        <li>
          6.6. Результати визначення Переможців Акції вважатимуться остаточними
          та не підлягають оскарженню.
        </li>
        <li>
          6.7. Представники Виконавця/Організатора або уповноважені ним особи
          повідомляють Переможців про їх перемогу в Акції телефонуючи кожному
          Переможцю за номером телефону, який він вказав під час реєстрації
          згідно п. 5.1.2 цих Правил
        </li>
        <li>
          6.8. Оподаткування вартості Призу проводиться відповідно до чинного
          законодавства України за встановленою для такого виду доходу ставкою.
          Організатор Акції, в якості податкового агента, проводить нарахування,
          утримання та перерахування до бюджету податку на доходи фізичних осіб
          та військового збору з отриманого Головним Переможцем Акції Заохочення
          та направляє до відповідного податкового органу передбачене законом
          повідомлення щодо передачі Заохочення Головному Переможцю Акції та
          утримані податок/збір. Організатор повідомляє податкові органи про
          Головного Переможця Акції.
        </li>
      </ol>
      <ol>
        <li>7. <strong>Отримання Заохочень Акції</strong></li>
        <li>
          7.1. Для отримання Заохочення Акції Учасник зазначеного у 4.1.1. цих
          Правил, визнаний Переможцем Акції, протягом 4 (чотирьох) календарних
          днів з моменту оголошення переможців, може зв’язатися з
          Виконавцем/Організатором Акції шляхом направлення індивідуального
          повідомлення на електрону адресу
          <a href="mailto:marketing-vtrua@vtrua.com">
            marketing-vtrua@vtrua.com</a
          >
          повідомивши його про готовність отримати Заохочення та про адресу, на
          яку слід відправити Заохочення
        </li>
        <li>
          <p>
            Переможець Другого рівня після отримання визначеного в п. 6.7.
            Правил повідомлення від Виконавця/Організатора зобов’язаний протягом
            4 (чотирьох) календарних днів відповісти Виконавцю/Організатору,
            повідомивши його про готовність отримати Заохочення та про адресу,
            на яку слід відправити Заохочення, а також надати
            Виконавцю/Організатору наступні документи\інформацію на електрону
            адресу
            <a href="mailto:marketing-vtrua@vtrua.com">
              marketing-vtrua@vtrua.com</a
            >
          </p>
          <ul>
            <li class="padding-left__20">П.І.Б.;</li>
            <li class="padding-left__20">
              адресу доставки, а саме номер відділення ТОВ «Нова пошта»;
            </li>
            <li class="padding-left__20">номер телефону;</li>
            <li class="padding-left__20">
              копію паспорту та копію реєстраційного номеру облікової картки
              платника податків (або копію паспорту громадянина України у якого
              наявна відмітка (в разі якщо платник через свої релігійні
              переконання відмовився від прийняття реєстраційного номера
              облікової картки платника податків та повідомив про це відповідний
              податковий орган), яка дає про право здійснювати будь-які платежі
              за серією та номером паспорта).
            </li>
          </ul>
        </li>
        <li>
          7.3. Якщо Переможець Акції вказав недостовірну / неточну / неповну
          інформацію, не виконав вимоги Правил або не надав свої контактні та
          інші передбачені цими Правилами дані / інформацію протягом 4
          (чотирьох) календарних днів із дати отримання ним повідомлення від
          Виконавця/Організатора, визначається новий Переможець. Визначення
          нового Переможця здійснюється в порядку, визначеному п.6.3. цих
          Правил. Виконавець/Організатор не надсилає повторно повідомлення
          Переможцю про його перемогу в Акції.
        </li>
        <li>
          7.4. Заохочення зазначені у п.4.1.1 даних Правил
          надсилаються/вручаються Замовником Переможцям, які виконали усі умови
          та п.4.1.2 даних Правил надсилаються/вручаються Виконавцем Переможцям,
          які виконали усі умови цих Правил, шляхом відправки через службу
          поштової (через відділення ТОВ «Нова пошта») доставки, на адресу
          відділення ТОВ «Нова пошта», вказану у повідомленні Переможця,
          протягом 7 (семи) календарних днів з моменту отримання необхідних
          документів\інформації від Переможця. Заохочення вважається врученим
          Переможцю Акції з моменту його передачі на відправлення у відділення
          ТОВ «Нова Пошта». Отримання Заохочень Акції у відділеннях служби
          поштової доставки здійснюється Переможцями у відповідності до правил
          служби поштової доставки.
        </li>
        <li>
          7.5. Вручення Заохочення допускається лише особі, яка отримала на
          нього право згідно з умовами цих Правил, і виключно в порядку,
          встановленому цими Правилами. У випадку, якщо Переможець Акції з
          яких-небудь причин не може отримати Заохочення особисто, такий
          Переможець не має права передати/поступитися своїм правом будь-якій
          третій особі.
        </li>
        <li>
          <p>
            7.6. В отриманні Заохочення Переможцю може бути відмовлено у
            наступних випадках:
          </p>
          <ul>
            <li class="padding-left__20">
              Переможець не надав документи\інформацію, п.7.2. даних Правил;
            </li>
            <li class="padding-left__20">
              при недотриманні умов даних Правил.
            </li>
          </ul>
        </li>
        <li>
          7.7. Увага! Виконавець/Організатор не несе відповідальності за
          достовірність наданих Учасником\Переможцем Акції
          документів\інформації. Виконавець/Організатор не несе відповідальності
          за роботу поштових служб та інших служб зв’язку, внаслідок яких
          Заохочення не надійшло, надійшло із запізненням, Заохочення було
          загублено чи пошкоджено.
        </li>
        <li>
          7.8. Неухильне дотримання всіх умов цих Правил та надання передбаченої
          інформації\документів є необхідною умовою для отримання Заохочення.
        </li>
        <li>
          7.9. У разі неможливості отримання Заохочення Переможцем Акції з
          причин, що не залежать від Виконавця/Організатора, в тому числі, через
          збої у роботі поштових служб, служб зв’язку, комп’ютерних систем, а
          також у разі виникнення форс-мажорних обставин: надзвичайної і
          непереборної сили, дія якої може бути викликана винятковими погодними
          умовами та\або стихійним лихом (ураган, буря, повінь, нагромадження
          снігу, ожеледь, землетрус, пожежа, просідання і зсув ґрунту тощо); або
          непередбачених ситуацій, що відбуваються незалежно від волі та бажання
          сторони / сторін (війна, теракти, блокада, страйк, АТО, окупація
          територій тощо), Виконавець / Організатор звільняється від своїх
          зобов'язань на час цих обставин , і таке заохочення використовується
          Виконавцем / Організатором на власний розсуд.
        </li>
        <li>
          7.10. Виконавець/Організатор не відповідає за виконання зобов'язань,
          опублікованих у цих Правилах, у випадку якщо Учасник / Переможець, не
          скористався відповідним правом у визначені терміни й порядку,
          передбаченими цими Правилами. Заохочення видаються тільки за умови
          виконання Учасниками / Переможцями всіх вимог, які передбачені даними
          Правилами.
        </li>
        <li>
          7.11. Виконавець/Організатор має право відмовити у видачі Заохочення
          Переможцю, якщо такий Переможець не виконав умов цих Правил. При цьому
          такий Переможець не має права на одержання від Виконавця/Організатора
          будь-якої компенсації.
        </li>
        <li>
          7.12. Виконавець/Організатор не несе відповідальності стосовно
          подальшого використання Заохочень наданих Переможцям після їх
          одержання, жодних гарантійних зобов’язань щодо якості Заохочення, що
          надані третіми особами, за неможливість Переможця скористатись наданим
          Заохоченням з будь-яких причин, а також за можливі наслідки
          використання таких Заохочень.
        </li>
        <li>
          7.13. Виконавець/Організатор не вступає в будь-які суперечки стосовно
          визнання будь-яких осіб Учасниками / Переможцями Акції і прав на
          отримання Заохочення Акції. Виконавець/Організатор не втручається в
          суперечки між Учасниками Акції та / або третіми особами, що пов’язані
          із проведенням Акції, та не бере на себе відповідальності за
          визначення прав сторін у будь-яких суперечках.
        </li>
      </ol>
      <ol>
        <li>8. <strong>Інші умови:</strong></li>
        <li>
          8.1. За вживання нецензурних виразів, образ як щодо Учасників Акції,
          так і щодо Виконавець/Організатора, такий Учасник Акції може бути без
          попередження позбавлений права користування Сайтом Акції.
        </li>
        <li>
          8.2. Не дозволяється використовувати Сайт Акції в незаконних цілях,
          особливо розміщувати або розсилати інформацію наклепницького, такого,
          що ганьбить, або образливого характеру в відношенні як до конкретної
          особи, так і групи осіб.
        </li>
        <li>
          8.3. При користуванні Сайтом Акції Учасникам Акції та відвідувачам
          Сайту Акції забороняється використовувати матеріали, які: містять або
          посилаються на віруси та інші шкідливі програми та файли, які здатні
          порушити нормальну роботу комп'ютерного обладнання або ПЗ; порушують
          авторські або суміжні права третіх осіб; містять будь-які еротичні чи
          порнографічні зображення або тексти; розпалюють міжнаціональну, расову
          чи релігійну ворожнечу, пропагують фашизм, містять сцени насильства
          або закликають до жорстокого поводження з тваринами; ображають інших
          користувачів Сайту Акції та/або носять непристойний характер; іншим
          чином порушують чинне законодавство України. У разі виявлення
          вищенаведеної інформації, контент буде блокуватися модератором Сайту
          Акції і буде видаленим з Сайту Акції.
        </li>
        <li>
          8.4. Виконавець/Організатор не компенсує Учасникам Акції вартість
          послуг операторів, що надають доступ в Інтернет, та не несе
          відповідальність за збої в їх роботі. Виконавець/Організатор не несе
          відповідальність і не робить виплат у разі втрати будь-якої
          інформації, пов'язаної з сервісами Сайту Акції і має цінності для
          Учасника Акції, якщо така втрата сталася в результаті збою в роботі
          оператора зв'язку, відключення електроенергії і т.п.
          Виконавець/Організатор не несе відповідальність за будь-які витрати,
          втрати або збиток, та що мали місце в результаті зловживання сервісами
          Сайту Акції або порушення даних правил будь-яким Учасником Акції.
          Учасник Акції також погоджується з тим, що Виконавець/Організатор не
          несе відповідальність за прямі або непрямі збитки (навіть у тому
          випадку, якщо Виконавець/Організатор був попереджений про можливість
          збитку), зокрема за втрату прибутку, втрату репутації, втрату даних,
          нематеріальний збиток і витрати, які були понесені внаслідок:
          використання або неможливості використання Сайту Акції; незаконного
          доступу до інформації, розміщеної на Сайті Акції, її зміни і
          пересилання; поведінки третіх осіб, що використовують Сайт Акції,
          тощо.
        </li>
        <li>
          8.5. Виконавець / Організатор не несе відповідальності у разі
          форс-мажорних обставин, таких як стихійні лиха, пожежа, повені,
          військові дії будь-якого виду, включаючи антитерористичні операції,
          блокади, суттєві зміни в законодавстві, що діє на території Акції,
          інші обставини, не залежні від Виконавця / Організатора.
        </li>
        <li>
          8.6. При порушенні Учасником Акції будь-якого з пунктів даних Правил
          Виконавець/Організатор має право на свій розсуд блокувати доступ
          такого Учасника до Сайту Акції, відмовивши йому у праві на подальше
          використання сервісів Сайту Акції.
        </li>
        <li>
          8.7. Дозволяється друкувати\пересилати інформацію та зміст Сайту Акції
          тільки в особистих, некомерційних цілях.
        </li>
        <li>
          8.8. У разі виникнення ситуацій, що допускають неоднозначне тлумачення
          цих Правил, та\або питань, не врегульованих цими Правилами, остаточне
          рішення приймається Виконавцем/Організатором відповідно до вимог
          чинного законодавства України. При цьому рішення
          Виконавця/Організатора є остаточним і не підлягає оскарженню.
        </li>
        <li>
          8.9. Виконавець/Організатор не несе відповідальності за не
          ознайомлення Учасника з даними Правилами.
        </li>
        <li>
          8.10. Беручи участь в Акції всі її Учасники та Переможці безкоштовно
          передають Виконавцю/Організатору всі виключні авторські та суміжні
          майнові права, які передбачені у ст.ст. 15, 39 Закону України «Про
          авторське право і суміжні права» на всі зображення, які беруть участь
          в Акції, включаючи, але не обмежуючись наступні: право на використання
          Виконавцем/Організатором твору та його частин, як у складі
          аудіовізуального твору так і окремо; право на заборону або дозвіл
          використання твору або його частин іншими особами; право на
          відтворення твору або його частин у будь-якій кількості, публічне
          виконання і публічне сповіщення твору/його частин у тому числі, але не
          виключно шляхом ефірного, кабельного, супутникового та інших видів
          телебачення, глобальних та локальних мереж, публічну демонстрацію і
          публічний показ твору/його частин, будь-яке повторне оприлюднення
          твору/його частин, переробки, адаптації, аранжування та інші подібні
          зміни твору/його частин, включення твору, як складових частин до інших
          творів, збірників, антологій, енциклопедій тощо, розповсюдження твору
          шляхом першого продажу, відчуження іншим способом або шляхом здавання
          в майновий найм чи у прокат та шляхом іншої передачі до першого
          продажу примірників твору, подання твору до загального відома публіки
          таким чином, що її представники змогли здійснити доступ до твору з
          будь-якого місця і у будь-який час за їх власним вибором, здавання
          примірників твору/його частин в майновий найм і (або) комерційний
          прокат, відчуження іншим способом оригіналу або примірників твору,
          експорт та імпорт примірників твору; інші майнові права
        </li>
        <li>
          8.11. Вартість користування мережею Інтернет, що здійснюється
          Учасником Акції, оплачується Учасником Акції самостійно за власний
          рахунок і відповідає стандартній вартості послуги за тарифним планом
          виконавця даної послуги. Всі Учасники Акції самостійно сплачують всі
          та будь-які витрати, понесені ними у зв'язку з участю в Акції (у тому
          числі, без обмежень, витрати, пов'язані з користуванням мережею
          Інтернет для отримання будь-якої інформації про Акції).
        </li>
        <li>
          8.12. Якщо з будь-якої причини будь-який аспект даної Акції не може
          проводитися так, як це заплановано, включаючи причини, викликані
          зараженням комп'ютерними вірусами, неполадками в мережі Інтернет,
          дефектами, маніпуляціями, несанкціонованим втручанням, фальсифікацією,
          технічними неполадками або будь-якою іншою причиною, неконтрольованою
          Виконавцем/Організатором Акції, яка спотворює або втручається у
          виконання, безпеку, чесність, цілісність або належне проведення Акції,
          Виконавець/Організатор може на свій власний розсуд анулювати,
          припинити, змінити або тимчасово припинити проведення Акції, або ж
          визнати недійсними будь-які реєстрації.
        </li>
        <li>
          8.13. Виконавець/Організатор Акції залишає за собою право не вступати
          в письмові переговори або інші контакти з Учасниками, за винятком
          випадків, вказаних в даних Правилах та чинному законодавстві України.
        </li>
        <li>
          8.14. Терміни, що використовуються в цих Правилах, стосуються виключно
          цієї Акції.
        </li>
        <li>
          8.15. Відповідно до вимог ст. 634 Цивільного кодексу України до
          договорів приєднання, а саме можливості укладання такого договору лише
          шляхом приєднання однієї сторони до запропонованого договору, своєю
          участю у Акції кожен Учасник свідчить і підтверджує, що ознайомлений і
          повністю згоден з цими Правилами Акції, буде їх дотримуватися та
          зобов’язується їх виконувати, що він ознайомлений з правами, які
          стосуються обробки та зберігання його Персональних даних.
        </li>
        <li>
          8.16. Учасники Акції, діючи вільно, своєю волею і в своїх інтересах,
          дають згоду Організатору, а також його афілійованим і / або
          уповноваженим особам, на обробку своїх персональних даних, що
          надаються Організатору у зв'язку з проведенням Акції, будь-якими
          способами, включаючи збір, запис, систематизацію, накопичення,
          зберігання, оновлення, уточнення, зміну, електронне копіювання,
          вилучення, використання, передачу, знеособлення, транскордонну
          передачу, блокування, видалення, знищення, для цілей проведення Акції
          і виконання пов'язаних з ним вимог законодавства. Дана згода є
          конкретною, поінформованою і свідомою. Переможець Акції погоджується з
          тим, що надані ним персональні дані, отримані відповідно до цих
          Правил, обробляються Організатором виключно з метою проведення Акції,
          в тому числі з метою виконання вимог чинного законодавства щодо
          податкового та бухгалтерського обліку.
        </li>
        <li class="text-indent__20">
          Фактом участі в Акції Учасник дає свою згоду на збір і обробку
          персональних даних, які надає Учасник під час проведення Акції
          включаючи внесення таких даних у базу даних Організатора, передачу
          таких даних третім особам. Виконавець гарантує, що під час збору і
          подальшого використання отриманої інформації та персональних даних він
          буде дотримуватися всіх положень чинного законодавства України щодо
          захисту особистої інформації, в т.ч. персональних даних, а також
          застосовувати всі доцільні заходи стосовно зібраної інформації і
          надавати її тим, хто має на це достатні законні підстави, і в порядку,
          визначеному чинним законодавством України.
        </li>
        <li>
          8.17. Крім того, Учасник підтверджує, що з моменту початку його участі
          в Акції він належним чином повідомлений про власника персональних
          даних, склад і зміст зібраних персональних даних, права суб'єкта
          персональних даних, передбачених Законом України «Про захист
          персональних даних», мету збору персональних даних та осіб, яким
          передаються його персональні дані.
        </li>
        <li>8.18. Ці Правила є єдиними офіційними правилами участі в Акції.</li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  mounted: function () {
    const home = document.querySelector(".nav__home");
    home.style.display = "inline-block";
    home.style.background = "#C1E7D8";
    const rules = document.querySelector(".nav__rules");
    rules.style.background = "transparent";
  },
};
</script>

<style lang="scss" scoped>
.page-rules {
  padding-top: 120px;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 20px;
  line-height: 1.5;
  @media (max-width: 1366px) {
    padding-top: 80px;
  }
  @media (max-width: 600px) {
    padding-top: 50px;
  }
  &__wrap {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
